<template>
  <v-card :loading="loading">
    <v-toolbar
      color="blue"
      dark
    >
      <v-toolbar-title>Tworzenie nowej faktury</v-toolbar-title>
    </v-toolbar>

    <v-card-text>

      <v-container>
        <v-simple-table v-if="invoice.invoice_entries && invoice.invoice_entries.length > 0">
          <thead>
            <tr>
              <th class="text-left">opiekun</th>
              <th class="text-left">nazwa pozycji</th>
              <th class="text-left">stawka VAT</th>
              <th class="text-left">kwota netto</th>
              <th class="text-left">kwota brutto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in invoice.invoice_entries"
              :key="entry.id"
            >
              <td>{{ entry.user.username }}</td>
              <td>{{ entry.product.name }}</td>
              <td>{{ entry.product.vat }}%</td>
              <td>{{ entry.amount | formatAmount}} PLN</td>
              <td>
                {{ (entry.amount + ((entry.amount * entry.product.vat) / 100)) | formatAmount }} PLN
              </td>
              <td>
                <v-icon
                  class="mr-5"
                  style="cursor: pointer;"
                  v-on:click="editEntry(entry)"
                >edit</v-icon>
                <v-icon
                  style="cursor: pointer;"
                  v-on:click="removeEntry(entry)"
                >mdi-close</v-icon>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
                {{ totalAmount(invoice.invoice_entries) | formatAmount }} PLN
              </td>
              <td class="font-weight-bold red--text">
                {{ total(invoice.invoice_entries) | formatAmount }} PLN
              </td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>

        <div class="text-center">
          <v-btn
            color="green"
            dark
            @click="isCreateEntryDialogActive=true"
          >dodaj pozycje

            <v-icon> add </v-icon>
          </v-btn>

          <v-dialog
            v-model="isCreateEntryDialogActive"
            max-width="800"
          >
            <InvoiceCreateEntry
              :invoice="invoice"
              v-on:cancel="isCreateEntryDialogActive = false"
              v-on:entry-created="isCreateEntryDialogActive = false"
              v-on:add-entry="addEntry"
            />
          </v-dialog>

          <InvoiceEditEntryDialog ref="editEntryDialog" :invoice="invoice" @save-entry="saveEntry"/>

        </div>
        <v-form>
          <v-row>
            <v-col>
              <v-text-field
                v-model="invoice.comment"
                label="komentarz dla klienta"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="invoice.comment_internal"
                label=" komentarz dla osoby wystawiającej"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-menu
                v-model="toBeIssuedAtDatepicker"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    v-model="invoice.to_be_issued_at"
                    label="kiedy wystawić"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="toBeIssuedAtErrors"
                    @click:clear="date = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="pl-pl"
                  no-title
                  :first-day-of-week="1"
                  v-model="invoice.to_be_issued_at"
                  :allowed-dates = "dates"
                  @change="toBeIssuedAtDatepicker = false"
                ></v-date-picker>
              </v-menu>

            </v-col>
            <v-col v-if="!invoice.id">
              <v-text-field
                v-model="invoice.to_be_payed_in"
                label="termin płatnośći"
                :error-messages="toBePayedInErrors"
              ></v-text-field>
            </v-col>
            <v-col v-if="!invoice.id">
              <v-select
                v-model="invoice.is_proforma"
                :items="documentTypes"
                item-text="name"
                item-value="id"
                label="dokument"
                clearable
                :error-messages="documentTypeErrors"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>

      </v-container>

    </v-card-text>
    <v-card-actions>

      <v-btn
        v-if="facility && facility.owner.facility_handles.length > 0 && facility.is_main === 1"
        color="orange darken-1"
        text
        v-on:click="$emit('create-related')"
      >
        zleć fakturę powiązaną
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        v-on:click="$emit('cancel')"
      >
        Anuluj
      </v-btn>
      <v-btn
        v-on:click="submit"
        color="blue darken-1"
        text
        :loading="loading"
      > Zleć wystawienie </v-btn>
    </v-card-actions>

    <ErrorMessage :error="error" />
    <Snackbar ref="snackbar" />
  </v-card>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";

import InvoiceCreateEntry from "./InvoiceCreateEntry";
import InvoiceEditEntryDialog from "./InvoiceEditEntryDialog";
import ErrorMessage from "../../../shared/ErrorMessage";
import moment from "moment";
import Snackbar from "../../../shared/Snackbar.vue";
export default {
  components: { InvoiceCreateEntry, InvoiceEditEntryDialog, ErrorMessage, Snackbar },
  props: ["subscription", "baseInvoice", "facility"],
  name: "InvoiceCreate",
  data() {
    return {
      invoice: {
        to_be_payed_in: 7,
        is_proforma: null,
        status: 0,
        invoice_entries: [],
      },
      toBeIssuedAtDatepicker: false,
      isCreateEntryDialogActive: false,
      documentTypes: [
        { id: 0, name: "Faktura" },
        { id: 1, name: "Proforma" },
      ],
      error: false,
      loading: false,
    };
  },
  validations: {
    invoice: {
      to_be_payed_in: { required, numeric /*, minValue: minValue(1)*/ },
      to_be_issued_at: { required },
      is_proforma: { required },
    },
  },
  computed: {
    toBePayedInErrors() {
      const errors = [];
      if (!this.$v.invoice.to_be_payed_in.$dirty) return errors;
      !this.$v.invoice.to_be_payed_in.required &&
        errors.push("termin jest wymagany");
      !this.$v.invoice.to_be_payed_in.numeric &&
        errors.push("podaj liczbę dni");
      /*!this.$v.invoice.to_be_payed_in.minValue &&
        errors.push("liczba musi być większa niż 0");*/
      return errors;
    },
    toBeIssuedAtErrors() {
      const errors = [];
      if (!this.$v.invoice.to_be_issued_at.$dirty) return errors;
      !this.$v.invoice.to_be_issued_at.required &&
        errors.push("termin jest wymagany");
      return errors;
    },
    documentTypeErrors() {
      const errors = [];
      if (!this.$v.invoice.is_proforma.$dirty) return errors;
      !this.$v.invoice.is_proforma.required &&
        errors.push("wybierz rodzaj dokumentu");
      return errors;
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      if (this.invoice.invoice_entries.length === 0) {
        this.error = "Brak pozycji na fakturze";
        return false;
      }
      this.invoice.subscription = this.subscription;

      this.loading = true;
      if (this.invoice.id && this.invoice.id !== null) {
        this.submitInvoiceByProforma();
      } else {
        this.$store
          .dispatch("ADD_INVOICE", {
            invoice: this.invoice,
          })
          .then((invoice) => {
            this.invoice.created_at = moment().format("Y-M-D H:m");
            this.subscription.invoices.push(invoice);
            this.$refs.snackbar.success("Faktura została dodana.");
            this.$emit("invoice-created");
            this.reset();
            this.loading = false;
          })
          .catch((error) => {
            this.error = "Nie udało się dodać faktury.(" + error + ")";
            this.loading = false;
          });
      }
    },
    submitInvoiceByProforma() {
      this.$store
        .dispatch("ADD_INVOICE_BY_PROFORMA", {
          invoice: this.invoice,
        })
        .then((invoice) => {
          let element = this.subscription.invoices.find(
            (i) => i.id === invoice.id
          );
          Object.assign(element, invoice);
          this.$emit("invoice-created");
          this.loading = false;
        })
        .catch((error) => {
          this.error = "Nie udało się dodać faktury.(" + error + ")";
          this.loading = false;
        });
    },
    addEntry: function (entry) {
      this.invoice.invoice_entries.push(entry);
    },
    editEntry: function (entry) {
      this.$refs.editEntryDialog.openEditDialog(entry);
    },
    saveEntry: function (entry) {
      const element = this.invoice.invoice_entries.find(currentEntry => currentEntry.id === entry.id);
      Object.assign(element, entry);
    },
    dates(val){
      if(this.facility && this.facility.client.invoice_type === 0){
        return [1].includes(new Date(val).getDay());
      } else {
        return val;
      }
    },
    removeEntry: function (entry) {
      let rs = this.invoice.invoice_entries.filter((currentEntry) => {
        return !(
          currentEntry.product.id === entry.product.id
        );
      });

      this.invoice.invoice_entries = [...rs];
    },
    totalAmount: function (entries) {
      return entries.reduce((acc, val) => {
        return acc + parseInt(val.amount);
      }, 0);
    },
    totalTax: function (entries) {
      return entries.reduce((acc, val) => {
        let tax = (val.amount * parseInt(val.product.vat)) / 100;
        return acc + tax;
      }, 0);
    },
    total: function (entries) {
      return this.totalAmount(entries) + this.totalTax(entries);
    },
    reset() {
      this.$v.$reset();
      this.invoice = {
        to_be_payed_in: 7,
        is_proforma: 0,
        status: 0,
        invoice_entries: [],
      };
    },
  },
  mounted() {
    this.invoice.invoice_entries = [];
    this.invoice.owner = this.owner;

    if (this.baseInvoice) {
      this.invoice = JSON.parse(JSON.stringify(this.baseInvoice));
      this.invoice.is_proforma = 0;
      this.invoice.status = 0;
      this.invoice.to_be_issued_at = null;
      this.invoice.to_be_payed_in = 0;
    }
  },
  watch: {
    "invoice.is_proforma": function (val) {
      this.invoice.to_be_payed_in = val === 1 ? 3 : 7;
    }  
  },
};
</script>


<style>
</style>