<template>
  <div>

    <v-btn
      icon
      dark
      @click="openSearchDialog"
    >
      <v-icon>groups</v-icon>
    </v-btn>
    <CoworkersStatusDialog ref="coworkersStatusDialog" />
  </div>
</template>

<script>

import CoworkersStatusDialog from "./UserCoworkers/CoworkersStatusDialog.vue";


export default {
  name: "UserQa",
  components: { CoworkersStatusDialog },
  data: () => ({

  }),
  computed: {
  },
  methods: {
    openSearchDialog(){
      this.$refs.coworkersStatusDialog.openDialog();
    },
  },
  mounted() {},
  watch: {},
};
</script>
