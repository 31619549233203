<template>
  <div>
    <v-card>
      <v-card-subtitle>Standardowe godziny pracy</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-menu
              ref="workingHoursStartTimepicker"
              v-model="workingHoursStartTimepicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="user.work_schedule.standardStart"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user.work_schedule.standardStart"
                  label="od"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="workingHoursStartTimepicker"
                v-model="user.work_schedule.standardStart"
                full-width
                format="24hr"
                @click:minute="$refs.workingHoursStartTimepicker.save(user.work_schedule.standardStart)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="workingHoursEndTimepicker"
              v-model="workingHoursEndTimepicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="user.work_schedule.standardEnd"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user.work_schedule.standardEnd"
                  label="do"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="workingHoursEndTimepicker"
                v-model="user.work_schedule.standardEnd"
                full-width
                format="24hr"
                @click:minute="$refs.workingHoursEndTimepicker.save(user.work_schedule.standardEnd)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-card-subtitle>Wyjatki w pracy (niestandardowe godziny, urlopy)</v-card-subtitle>
      <v-card-text class="text-right">
        <v-btn
          absolute
          top
          right
          color="success"
          @click="createWorkingScheduleException"
        >dodaj wyjątek</v-btn>
        <WorkingScheduleExceptionCreateDialog ref="workingScheduleExceptionCreateDialog" />
      </v-card-text>
      <v-card-text>
        <div>
          <v-row
            v-for="workScheduleException in USER_WORK_SCHEDULE_EXCEPTIONS"
            :key="workScheduleException.id"
            style="border-bottom:1px solid #dbdbdb;"
          >
            <v-col sm=2>

              <v-icon
                :color="workScheduleException.type === 1 ? 'orange' : 'blue'"
                class="mr-1"
              >circle</v-icon>
              <span v-if="workScheduleException.type === 1">urlop</span>
              <span v-if="workScheduleException.type === 0">zmiana godzin</span>
            </v-col>
            <v-col sm=6>
              <v-chip
                v-for="date in workScheduleException.dates"
                :key="date"
                class="ml-2"
                small
              >
                {{ date }}
              </v-chip>
            </v-col>
            <v-col>
              {{ workScheduleException.description }}
            </v-col>
            <v-col sm=1>
              <v-btn
                color="error"
                @click="remove(workScheduleException)"
                small
              ><v-icon>delete</v-icon></v-btn>
            </v-col>
          </v-row>

        </div>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WorkingScheduleExceptionCreateDialog from "./UserEditWorkingSchedule/WorkingScheduleExceptionCreateDialog.vue";

export default {
  name: "UserEditTextButton",
  props: ["user"],
  components: { WorkingScheduleExceptionCreateDialog },
  data: () => ({
    workingHoursStartTimepicker: false,
    workingHoursEndTimepicker: false,
  }),
  computed: {
    ...mapGetters(["USER_WORK_SCHEDULE_EXCEPTIONS"]),
  },
  methods: {
    add: function () {
      if (this.user.quick_texts === null) {
        this.user.quick_texts = [];
      }
      this.user.quick_texts.push({
        id: Date.now(),
        name: "",
        template: "",
      });
    },
    remove: function (workScheduleException) {
      this.loading = true;
      this.$store.dispatch("REMOVE_USER_WORK_SCHEDULE_EXCEPTION",{
          userWorkScheduleException: workScheduleException,
        }).then(() => {
        this.loading = false;
      });
    },
    createWorkingScheduleException() {
      this.$refs.workingScheduleExceptionCreateDialog.openDialog();
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("GET_USER_WORK_SCHEDULE_EXCEPTIONS").then(() => {
      this.loading = false;
    });
  },
};
</script>


<style>
</style>