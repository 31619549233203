import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './../components/Home.vue'
import AppLayout from './../layouts/App.vue'

import Dashboard from './../components/Dashboard.vue'
import Invoice from './../components/Invoice.vue'
import Login from './../components/Login.vue'
import FacilityEdit from './../components/Facility/FacilityEdit.vue'
import WindowSendEmail from './../components/Facility/FacilityEdit/Window/WindowSendEmail.vue'
import WindowSendSms from './../components/Facility/FacilityEdit/Window/WindowSendSms.vue'
import WindowGoogleMatch from './../components/Facility/FacilityEdit/Window/WindowGoogleMatch.vue'
import WindowGpsEdit from './../components/Facility/FacilityEdit/Window/WindowGpsEdit.vue'
import WindowGpsRange from './../components/Facility/FacilityEdit/Window/WindowGpsRange.vue'
import WindowStats from './../components/Facility/FacilityEdit/Window/WindowStats.vue'
import ListingSearch from './../components/Listing/ListingSearch.vue'
import FacilityCreate from './../components/Facility/FacilityCreate.vue'
import Admin from './../components/Admin.vue'

//import PublicLayout from './layouts/Public.vue'

Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    base: "/",
    routes: [
        {
          path: "/",
          component: Home,
          name: "home",
          meta: {
            rule: 'public'
          }
        },
        {
          path: "/app",
          name: 'app',
          component: AppLayout,
          children: [
            {
              name:'appDefault',
              path: '',
              component: Dashboard,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name:'invoice',
              path: 'invoice',
              component: Invoice,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name:'listingSearch',
              path: 'listing/search',
              component: ListingSearch,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityEdit',
              path: 'facility/edit/:id',
              component: FacilityEdit,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityEditSendEmail',
              path: 'facility/edit/:id/sendEmail',
              component: WindowSendEmail,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityEditSendSms',
              path: 'facility/edit/:id/sendSms',
              component: WindowSendSms,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityEditGoogleMatch',
              path: 'facility/edit/:id/googleMatch',
              component: WindowGoogleMatch,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityEditGps',
              path: 'facility/edit/:id/gps',
              component: WindowGpsEdit,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityEditStats',
              path: 'facility/edit/:id/stats',
              component: WindowStats,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityGpsRange',
              path: 'facility/edit/:id/gpsRange',
              component: WindowGpsRange,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'facilityCreate',
              path: 'facility/create',
              component: FacilityCreate,
              meta: {
                rule: 'supervisor'
              }
            },
            {
              name: 'admin',
              path: 'admin',
              component: Admin,
              meta: {
                rule: 'admin'
              }
            },
          ],
        },
        {
          path: "/login",
          component: Login,
          name: "login",
          meta: {
            rule: 'public'
          },
          children: [
            {
              path: '',
              component: Login,
              meta: {
                rule: 'public'
              }
            },
          ],
        }
      ]
  });