<template>
  <v-card flat>
    <v-row dense>
      <v-col></v-col>
      <v-col cols="6">
        <v-toolbar
          dense
          flat
          :class="((viewMode === 'tab')?'mb-n12':'')"
          style="z-index:2;"
          color="transparent"
        >
          <v-spacer></v-spacer>
          <v-tooltip
            bottom
            color="info"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="info"
                small
                @click="invoiceListDialog=true"
                class="mr-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>receipt_long</v-icon>
              </v-btn>
            </template>
            <span>zobacz faktury obiektu</span>
          </v-tooltip>

          <v-tooltip
            bottom
            color="success"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                @click="newSubscriptionConfirmationDialog = true"
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>rozpocznij nową emisje</span>
          </v-tooltip>

          <v-divider
            vertical
            class="mx-2"
          ></v-divider>
          <v-btn-toggle
            v-model="viewMode"
            mandatory
            dense
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  value="tab"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>tab</v-icon>
                </v-btn>
              </template>
              <span>widok zakładek</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  value="list"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>list</v-icon>
                </v-btn>
              </template>
              <span>widok całej listy emisji</span>
            </v-tooltip>

          </v-btn-toggle>
        </v-toolbar>
      </v-col>
    </v-row>
    <template v-if="viewMode === 'list'">
      <v-card
        v-for="subscription in subscriptions"
        :key="subscription.id"
        class="mb-3"
      >
        <v-toolbar
          color="blue"
          dark
          dense
        >
          <v-toolbar-title>{{ makeTitle(subscription) }}</v-toolbar-title>
        </v-toolbar>
        <SubscriptionEdit
          :subscription="subscription"
          :archive="isArchive(subscription)"
          :facility="facility"
        />
      </v-card>
    </template>
    <template v-if="viewMode === 'tab'">
      <FacilityEditSubscriptionTabs :facility="facility" />
    </template>

    <v-dialog
      v-model="invoiceListDialog"
      max-width="1200"
    >
      <FacilityEditInvoiceList
        :facility="facility"
        v-on:close="invoiceListDialog=false"
      />
    </v-dialog>

    <v-dialog
      v-model="newSubscriptionConfirmationDialog"
      max-width="700"
      persistent
    >
      <v-card class="pb-5">
        <v-card-title>
          <v-container class="text-center">Czy na pewno chcesz rozpocząć kolejną emisje?</v-container>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="newSubscriptionConfirmationDialog=false;addSubscription()"
          >tak, rozpoczynam nową emisje</v-btn>
          <v-btn
            color="warning"
            @click="newSubscriptionConfirmationDialog=false;"
          >nie, nie chce tworzyć nowej emisji</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Snackbar ref="snackbar" />
  </v-card>
</template>

<script>
//import { mapGetters } from "vuex";
import SubscriptionEdit from "./Subscription/SubscriptionEdit";
import FacilityEditSubscriptionTabs from "./FacilityEditSubscriptionTabs";
import FacilityEditInvoiceList from "./FacilityEditInvoiceList";

import Snackbar from "./../../shared/Snackbar";

import moment from "moment";

export default {
  name: "FacilityEditSubscription",
  props: ["facility"],
  components: {
    SubscriptionEdit,
    FacilityEditSubscriptionTabs,
    FacilityEditInvoiceList,
    Snackbar,
  },
  data: () => ({
    viewMode: "tab",
    invoiceListDialog: false,
    newSubscriptionConfirmationDialog: false
  }),
  computed: {
    subscriptions() {
      return this.facility.subscriptions.slice().reverse();
    },
  },
  methods: {
    makeTitle: function (subscription) {
      let title;

      if (subscription.end === null && subscription.start === null) {
        title =
          (subscription.name !== null ? subscription.name : "") +
          " - brak emisji";
      } else {
        title =
          (subscription.name !== null ? "[" + subscription.name + "] " : "") +
          "Emisja od " +
          (subscription.start === null
            ? ".."
            : moment(String(subscription.start)).format("Y-MM-DD")) +
          " do " +
          (subscription.end === null
            ? ".."
            : moment(String(subscription.end)).format("Y-MM-DD"));
      }

      return title;
    },
    isArchive: function (subscription) {
      let lastSubscription = Object.values(this.facility.subscriptions).slice(
        -1
      )[0];
      if (
        subscription.id &&
        subscription.id !== lastSubscription.id &&
        subscription.aggred_at == null &&
        subscription.invoices.length === 0
      ) {
        return true;
      }
      return false;
    },
    addSubscription: function () {
      let lastSubscription = Object.values(this.facility.subscriptions).slice(
        -1
      )[0];
      lastSubscription = !lastSubscription ? {} : lastSubscription;
      if (lastSubscription.start === null || lastSubscription.end === null) {
        this.$refs.snackbar.error(
          "Nie udało się utworzyć emisji, obecna nie ma określonego czasu trwania"
        );
        return;
      }
      
      if (lastSubscription.invoices.length === 0 && (lastSubscription.package === null || lastSubscription.package.id !== 1)) {
        this.$refs.snackbar.error(
          "Nie udało się utworzyć emisji, obecna nie ma faktur"
        );
        return;
      }
      console.log(lastSubscription);
      // let subscription = JSON.parse(JSON.stringify(lastSubscription));
      let subscription = {};
      subscription.id = -new Date();
      //subscription.start = lastSubscription.end;
      subscription.start = null;
      subscription.created_at = moment(new Date()).format("Y-MM-DD");
      subscription.invoices = {};
      subscription.package = null;
      subscription.end = null;
      subscription.aggred_at = null;
      subscription.client_name = lastSubscription.client_name;
      subscription.client_phone = lastSubscription.client_phone;
      subscription.client_phone2 = lastSubscription.client_phone2;
      subscription.client_description = lastSubscription.client_description;
      subscription.client_title = lastSubscription.client_title;
      subscription.client_hours_from = lastSubscription.client_hours_from;
      subscription.client_hours_to = lastSubscription.client_hours_to;
      subscription.user = lastSubscription.user;
      subscription.comment = "";
      subscription.agreement_description = null

      this.facility.subscriptions.push(subscription);
    },
  },
  mounted() {},
};
</script>


<style>
</style>