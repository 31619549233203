<template>
  <v-autocomplete
    v-model="filter.extra"
    :items="extraSearch"
    dense
    small-chips
    deletable-chips
    color="blue-grey lighten-2"
    label="ekstra filtry"
    multiple
    :menu-props="{ maxHeight: 700 }"
  >
    <template v-slot:item="data">
      <v-list-item-content class="ma-0 pa-1">
        <v-list-item-title v-html="data.item.text"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "ExtraFilter",
  data: () => ({
    extraSearch: [
      {
        text: "aktywny roomadmin",
        value: "roomadmin_active",
        description: "obiekty z aktywnym roomadminem (demo i płatny)",
      },
      {
        text: "brak konta roomadmin",
        value: "roomadmin_not_exist",
        description: "obiekty z brakiem powiązanego konta roomadmin",
      },
      {
        text: "niezapłacona faktura",
        value: "invoice_not_payed",
        description: "niezapłacona fakutra w ostatniej emisji",
      },
      {
        text: "zapłacona faktura",
        value: "invoice_payed",
        description: "zapłacona faktura w ostatniej emisji",
      },
      {
        text: "aktywne usługi www",
        value: "meteor_sites_active",
        description: "aktywne jakiekolwiek usługi 'Strony Meteor'",
      },
      {
        text: "brak usług www",
        value: "meteor_sites_not_active",
        description: "brak aktywnych usługi 'Strony Meteor'",
      },
      {
        text: "aktywne linki",
        value: "is_partner",
        description: "aktualnie umieszczne linki partnerskie",
      },
      {
        text: "brak linków, posiada www",
        value: "is_not_partner",
        description: "brak aktualnie umieszczonych linków partnerskich",
      },
      {
        text: "brak wysłanej oferty linków",
        value: "partner_offer_not_sent",
        description: "brak wysłanej oferty 'Partner Meteor'",
      },
      {
        text: "strona w domenie business.site",
        value: "has_business_site",
        description: "posiadał stronę w domenie business.site",
      },
      {
        text: "posiada booking.com",
        value: "has_booking_com",
        description: "posiada wpisany link do obiektu na booking.com",
      },
      {
        text: "nie posiada booking.com",
        value: "has_not_booking_com",
        description: "nie posiada wpisanego linku do obiektu na booking.com",
      },
      {
        text: "posiada związane",
        value: "is_related",
        description: "posiada obiekty związane",
      },
      {
        text: "klient bez faktury",
        value: "is_client_without_invoice",
        description: "posiada uzgodnienie ale nie ma faktur",
      },
      {
        text: "faktura tradycyjnie",
        value: "is_invoice_traditional",
        description: "ma ustawione tradycyjny sposób wysyłki faktury",
      },
      {
        text: "brak email",
        value: "has_not_email",
        description: "obiekt nie posiada adresu email",
      },
      {
        text: "brak zdjęć",
        value: "image_count_is_null",
        description: "obiekt nie posiada zdjęć",
      },
      {
        text: "brak atrybutów",
        value: "attribute_count_is_null",
        description: "obiekt nie posiada atrybutów",
      },
      {
        text: "brak pobrania atrybutów",
        value: "attributes_not_downloaded",
        description: "brak pobrania automatycznego atrybutów",
      },
      {
        text: "nieaktualne terminy dostępności obiektu",
        value: "is_client_panel_seasons_out_of_date",
        description: "dostępność obiektu w panelu klienta jest nieaktualna",
      },
      {
        text: "brak terminów dostępności obiektu",
        value: "is_client_panel_seasons_empty",
        description: "dostępność obiektu w panelu klienta nie jest wprowadzona",
      },
      {
        text: "brak w pełni uzupełnionego cennika",
        value: "is_client_panel_pricelist_empty",
        description: "w cenniku brakuje pojemności, ilości lub ceny",
      },
      {
        text: "obiekt nie do prezentacji",
        value: "is_not_active",
        description: "obiekt z wyłączoną prezentacją",
      },
      {
        text: "niepoprawny format telefonu",
        value: "phone_not_match",
        description: "telefon obiektu nie pasuje do wzorca",
      },
      {
        text: "brak Pan/Pani w emisji",
        value: "subscription_client_title_is_empty",
        description:
          "w bieżącej emisji nie określono tytułu (Pan/Pani) klienta a wpisano imię i nazwisko",
      },
    ],
  }),
  props: {
    filter: {
      type: Object,
    },
  },
  computed: {},
  mounted() {},
  methods: {

  },
  watch: {

  },
};
</script>

<style>
</style>
