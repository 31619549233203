<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-top-transition"
  >

    <v-card>
      <v-card-title>

      </v-card-title>
      <v-card-text>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-combobox
              v-model="workingScheduleException.dates"
              multiple
              chips
              small-chips
              label="dni"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-combobox>
          </template>
          <v-date-picker
            v-model="workingScheduleException.dates"
            multiple
            no-title
            scrollable
            locale="pl"
            :first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Anuluj
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(workingScheduleException.dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-select
          :items="items"
          label="rodzaj"
          v-model="workingScheduleException.type"
          clearable
        ></v-select>
        <v-text-field
          v-model="workingScheduleException.description"
          label="opis"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          Anuluj
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="create()"
        >
          Dodaj
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
export default {
  name: "WorkingScheduleExceptionCreateDialog.vue",
  data: () => ({
    dialog: false,
    loading: false,
    workingScheduleException: {},
    items: [
      { value: "0", text: "zmiana godzin pracy" },
      { value: "1", text: "urlop" },
    ],
    dates: [],
    menu: false,
  }),
  components: {},
  computed: {},
  mounted() {},
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    create(){
      this.dialog = true;
      this.$store
        .dispatch("ADD_USER_WORK_SCHEDULE_EXCEPTION", {
          userWorkScheduleException: this.workingScheduleException,
        })
        .then(() => {
          this.loading = false;
          this.workingScheduleException = {};
          this.closeDialog();
          this.$emit("updated");
        });
    }
  },
  watch: {},
};
</script>


<style>
</style>