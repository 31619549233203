<template>
  <v-card
    :loading="loading"
    flat
  >
    <v-card-title class="pb-0">Odbiorcy</v-card-title>
    <v-alert
      dense
      outlined
      type="error"
      v-if="receiversErrors.length > 0"
      class="mx-3"
    >
      {{ receiversErrors.join('\n') }}
    </v-alert>
    <v-card-text>
      <v-alert
        v-if="phones.length === 0"
        dense
        outlined
        type="error"
        class="mx-3"
      >Brak zdefiniowanych numerów telefonów komórkowych.</v-alert>
      <v-item-group
        multiple
        v-model="message.receivers"
      >
        <v-item
          v-for="(item, i) in phones"
          :key="i"
          :value="item"
          v-slot="{ active, toggle }"
        >
          <v-chip
            class="mr-2"
            active-class="green darken-1 white--text"
            :input-value="active"
            @click="toggle"
          >
            {{ item }}
          </v-chip>
        </v-item>
      </v-item-group>
    </v-card-text>

    <v-card-title class="py-0 my-0">Treść wiadomości</v-card-title>
    <v-card-text>
      <SendSMSQuickText
        @insertText="insertText"
        :facility="facility"
      ></SendSMSQuickText>
      <v-textarea
        ref="content"
        v-model="message.content"
        auto-grow
        counter
        filled
        :error-messages="contentErrors"
      ></v-textarea>
      <v-alert
        type="warning"
        dense
        v-if="!CURRENT_USER.sms_phone"
      >SMS będzie wysłany z losowego numeru telefonu</v-alert>
      <v-alert
        type="success"
        dense
        v-if="CURRENT_USER.sms_phone"
      >SMS będzie wysłany z numeru: {{CURRENT_USER.sms_phone}}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="primary"
        @click="send()"
      > wyślij wiadomość </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import axios from "axios";

import SendSMSQuickText from "./SendSMS/SendSMSQuickText.vue";

export default {
  name: "FacilityEditSendSMS",
  props: {
    facility:{
      type: Object
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { SendSMSQuickText },
  data: () => ({
    loading: false,
    subscriptionTab: null,
    message: {
      receivers: [],
      content: "",
    },
  }),
  validations: {
    message: {
      receivers: { required },
      content: { required },
    },
  },
  computed: {
    ...mapGetters(["MESSAGE_TEMPLATES"]),
    ...mapGetters(["CURRENT_USER"]),
    phones() {
      let phones = [];
      let facilityPhones = this.facility.facility.phones.filter((current) => {
        return [2, 3, 4, 6, 7].includes(current.type.id);
      });

      facilityPhones.forEach((phone) => {
        let number = phone.number.replace(/\s/g, "");
        if (!phones.includes(number)) {
          phones.push(number);
        }
      });

      let lastSubscription = Object.values(this.facility.subscriptions).slice(
        -1
      )[0];

      if (
        lastSubscription.client_phone &&
        lastSubscription.client_phone.length > 0
      ) {
        if (
          !phones.includes(lastSubscription.client_phone.replace(/\s/g, ""))
        ) {
          phones.push(lastSubscription.client_phone.replace(/\s/g, ""));
        }
      }

      if (
        lastSubscription.client_phone2 &&
        lastSubscription.client_phone2.length > 0
      ) {
        if (
          !phones.includes(lastSubscription.client_phone2.replace(/\s/g, ""))
        ) {
          phones.push(lastSubscription.client_phone2.replace(/\s/g, ""));
        }
      }

      if (this.facility.people && this.facility.people.length > 0) {
        this.facility.people.forEach((person) => {
          let number = person.phone.replace(/\s/g, "");
          if (!phones.includes(number)) {
            phones.push(number);
          }
        });
      }

      return phones;
    },
    /**
     * validation errors
     */
    receiversErrors() {
      const errors = [];
      if (!this.$v.message.receivers.$dirty) return errors;
      !this.$v.message.receivers.required && errors.push("wybierz odbiorce");
      return errors;
    },
    contentErrors() {
      const errors = [];
      if (!this.$v.message.content.$dirty) return errors;
      !this.$v.message.content.required &&
        errors.push("treść wiadomości jest wymagana");
      return errors;
    },
  },
  methods: {
    send() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return false;
      }

      this.loading = true;

      axios
        .post(`/facilities/${this.facility.id}/smsMessages`, this.message)
        .then(() => {
          this.loading = false;
          this.reset();
          this.$emit("FacilitySendSMS:sended");
        });
    },
    reset() {
      this.$v.$reset();
      this.message = {
        receivers: [],
        content: "",
      };
    },
    insertText(text) {
      this.message.content = text;
      this.focusContent();
      this.$nextTick(() => {
        this.$refs.content.$refs.input.setSelectionRange(
          text.length,
          text.length
        );
      });
    },
    focusContent() {
      setTimeout(() => {
        this.$refs.content.$refs.input.focus();
      });
    },
    selectReceiver(number) {
      let receiver = number.replace(/\s/g, "");
      if (this.phones.includes(receiver)) {
        this.message.receivers.push(receiver);
      }
    },
  },
  watch: {
    facility: function () {
      this.message = {
        content: "",
      };
    },
    "options.selectReceiver": function (number) {
      this.message.receivers = [];
      this.selectReceiver(number);
    },
  },
  mounted() {
    if(this.options.selectReceiver){
      this.selectReceiver(this.options.selectReceiver);
    }
  },
};
</script>


<style>
</style>