<template>
  <v-container fluid>
    <template v-if="!archive">
      <v-row>
        <v-col md="3">
          <v-menu
            v-model="startsAtDatepicker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="subscription.start"
                :disabled="subscription.agreed_at == null"
                label="początek"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              v-model="subscription.start"
              :first-day-of-week="1"
              @change="startsAtDatepicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="expiresAtDatepicker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="subscription.end"
                :disabled="subscription.agreed_at == null"
                label="koniec"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              locale="pl-pl"
              no-title
              v-model="subscription.end"
              :first-day-of-week="1"
              @change="expiresAtDatepicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="6">
          <v-autocomplete
            v-model="subscription.user"
            :items="OPERATORS"
            item-text="name"
            return-object
            label="doradca biznesowy"
            dense
            :menu-props="{ maxHeight: 520, offsetOverflow: true }"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <SubscriptionEditClient :subscription="subscription" />
      <v-row dense>
        <v-col
          md="4"
          class="py-0"
        >
          <v-select
            :items="PACKAGES"
            :disabled="subscription.agreed_at == null"
            v-model="subscription.package"
            item-text="name"
            return-object
            label="pakiet"
            clearable
            dense
            @change="packageChange"
          >
          </v-select>
        </v-col>

        <v-col
          md="4"
          class="py-0"
        >
          <SubscriptionAgreedAtPicker v-model="subscription.agreed_at" />
          <!--v-datetime-picker
          ref="dateTimePicker"
          name="agreed_at"
          label="uzgodniono"
          clearText="Anuluj"
          :time-picker-props="{format:'24hr',min:'7:00',max:'18:00'}"
          :date-picker-props="{locale:'pl','first-day-of-week':1}"
          :textFieldProps="{'dense':true}"
        ></v-datetime-picker>-->
        </v-col>
        <v-col
          md="3"
          class="pa-0"
        >
          <v-radio-group
            v-model="subscription.client_status"
            :disabled="subscription.agreed_at == null"
            row
            dense
            class="my-0 mx-1 justify-center"
            id="radioClientStatus"
          >
            <v-radio
              label="stały"
              :value=0
              :key=0
            ></v-radio>
            <v-radio
              label="nowy"
              :value=1
              :key=1
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          md="1"
          class="text-center py-0"
        >
          <v-btn
            small
            :disabled="subscription.agreed_at == null"
            color="green"
            class="white--text px-1"
            @click="initNewInvoice()"
          >F <v-icon> add </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="12">
          <v-textarea
            label="uzgodniono:"
            auto-grow
            rows="1"
            dense
            outlined
            hide-details
            @keydown.ctrl.68.prevent=""
            @keydown.ctrl.68="addEmptyAgreementDescriptionLine(subscription)"
            ref="agreementDescription"
            v-model="subscription.agreement_description"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row dense>
        <SubscriptionInvoice
          ref="subscriptionInvoice"
          :subscription="subscription"
          :facility="facility"
        />
        <div style="position:relative;width:100%;min-height:3px;" v-if="subscription.invoices.length > 0">
          <v-btn
            color="grey darken-3"
            outlined
            fab
            dark
            x-small
            absolute
            elevation="0"
            right
            bottom
            class="mb-5"
            @click="openDebtInfo()"
          >
            <v-badge
              v-if="subscription.invoices.length > 0"
              color="grey darken-3"
              :content="debtInfoLines"
              :value="debtInfoLines>0?1:0"
            >
              <strong style="font-size:1.1rem">W</strong>
            </v-badge>
          </v-btn>
        </div>
      </v-row>
    </template>
    <v-row dense>
      <v-col
        cols="12"
        class="py-0"
      >
        <CommentButtons v-on:add-comment-line="addCommentLine($event, subscription)" />
        <v-textarea
          outlined
          ref="comment"
          id="facility-comment"
          @keydown.ctrl.68.prevent=""
          @keydown.ctrl.68="addEmptyCommantLine(subscription)"
          v-model="subscription.comment"
          label="komentarz"
          auto-grow
          rows="4"
          dense
        >

        </v-textarea>

      </v-col>
    </v-row>
    <SubscriptionDebtInfo
      :subscription="subscription"
      ref="subscriptionDebtInfo"
    />
    <SubscriptionNewInvoiceCheckout
      v-on:subscriptionNewInvoiceCheckout:accepted="openNewInvoiceDialog()"
      :facility="facility"
      :subscription="subscription"
      ref="subscriptionNewInvoiceCheckout"
    />
    <Snackbar ref="snackbar" />
  </v-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import SubscriptionEditClient from "./SubscriptionEdit/SubscriptionEditClient";
import SubscriptionAgreedAtPicker from "./SubscriptionEdit/SubscriptionAgreedAtPicker";
import SubscriptionInvoice from "./SubscriptionInvoice";
import SubscriptionNewInvoiceCheckout from "./SubscriptionNewInvoiceCheckout";
import SubscriptionDebtInfo from "./SubscriptionDebtInfo";
import CommentButtons from "../../../shared/CommentButtons";
import Snackbar from "./../../../shared/Snackbar";

export default {
  name: "FacilityEditSubscription",
  props: ["subscription", "archive", "facility"],
  components: {
    SubscriptionInvoice,
    CommentButtons,
    SubscriptionDebtInfo,
    SubscriptionNewInvoiceCheckout,
    SubscriptionEditClient,
    SubscriptionAgreedAtPicker,
    Snackbar,
  },
  data: () => ({
    startsAtDatepicker: false,
    expiresAtDatepicker: false,
    agreedAtDatepicker: false,
    clientHourFromTimepicker: false,
  }),
  computed: {
    ...mapGetters(["PACKAGES"]),
    ...mapGetters(["OPERATORS"]),
    ...mapGetters(["CURRENT_USER"]),
    isEdidatable() {
      if (this.subscription.agreed_at !== null) {
        let now = moment().subtract(5, "days");
        let aggredAt = moment(this.subscription.agreed_at);
        if (aggredAt < now) {
          return false;
        }
      }
      return true;
    },
    debtInfoLines() {
      let length = 0;
      if (
        this.subscription.debt_info &&
        this.subscription.debt_info.length > 0
      ) {
        length = this.subscription.debt_info.split(/\r\n|\r|\n/).length;
      }
      return length;
    },
  },
  methods: {
    addEmptyCommantLine(subscription) {
      let date = moment(date).format("YYYY-MM-DD H:mm");
      let line = "[" + date + "] " + this.CURRENT_USER.username + ", ";
      this.addCommentLine(line, subscription);
    },
    addCommentLine(line, subscription) {
      subscription.comment =
        line +
        "\n" +
        (subscription.comment === null ? "" : subscription.comment);
      this.focusComment();
      this.$nextTick(() => {
        this.$refs.comment.$refs.input.setSelectionRange(
          line.length,
          line.length
        );
      });
    },
    focusComment() {
      setTimeout(() => {
        this.$refs.comment.$refs.input.focus();
      });
    },
    addEmptyAgreementDescriptionLine(subscription) {
      let date = moment(date).format("YYYY-MM-DD H:mm");
      let line = "[" + date + "] " + this.CURRENT_USER.username + ", ";
      this.addAgreementDescriptionLine(line, subscription);
    },
    addAgreementDescriptionLine(line, subscription) {
      subscription.agreement_description =
        line +
        (subscription.agreement_description === null ||
        subscription.agreement_description.length < 1
          ? ""
          : "\n" + subscription.agreement_description);
      this.focusAgreementDescription();
      this.$nextTick(() => {
        this.$refs.agreementDescription.$refs.input.setSelectionRange(
          line.length,
          line.length
        );
      });
    },
    focusAgreementDescription() {
      setTimeout(() => {
        this.$refs.agreementDescription.$refs.input.focus();
      });
    },
    initAgreedAt() {
      console.log(this.subscription.agreed_at);
      if (this.subscription.agreed_at === null) {
        this.subscription.agreed_at = moment().format("YYYY-MM-DD H:mm");
      }
    },
    openDebtInfo() {
      this.$refs.subscriptionDebtInfo.openDialog();
    },
    initNewInvoice() {
      if (!this.subscription.id || this.subscription.id < 0) {
        this.$refs.snackbar.error(
          "Aby dodać faktury do subscrypcji zapisz wprowadzone zmiany."
        );
      } else {
        if(this.isPaidProforma()){
          this.$refs.snackbar.error(
            "W wybranej emisji istnieje zapłacona faktura proforma. Aby ją wystawić kliknij 'ZLEĆ FV' w szczegółach faktury"
          );
        } else {
          this.$refs.subscriptionNewInvoiceCheckout.openDialog();
        }        
      }
    },
    openNewInvoiceDialog() {
      this.$refs.subscriptionInvoice.openNewInvoiceDialog();
    },
    closeClientHourFromTimepicker(v) {
      v = v < 10 ? "0" + v : v;
      this.subscription.client_hours_from = v + ":00";
      this.clientHourFromTimepicker = false;
    },
    isPaidProforma(){
      let status = false;
      this.subscription.invoices.forEach((invoice) => {
        if (invoice.status === 2 && invoice.is_proforma) {
          status = true;
        }
      });
      return status;
    },
    packageChange() {
      if (
        this.subscription.agreed_at &&
        (this.subscription.agreement_description === null ||
          this.subscription.agreement_description.length < 1)
      ) {
        let date = moment(date).format("YYYY-MM-DD H:mm");
        let line =
          "[" +
          date +
          "] " +
          this.CURRENT_USER.username +
          ", P. " +
          this.subscription.package.name;
        this.addAgreementDescriptionLine(line, this.subscription);
      }
    },
  },
  mounted() {
    this.$store.dispatch("GET_PACKAGES");
    this.$store.dispatch("GET_OPERATORS");
  },
  watch: {
    "subscription.start": function (start) {
      if (start != null) {
        //  let end = moment(start).add(1,'year').subtract(1,'days');
        //  this.subscription.end = end.format("YYYY-MM-DD");
      }
    },
    "subscription.agreed_at": function () {
      if (!this.subscription.agreed_at) {
        if (
          this.subscription.end &&
          this.subscription.invoices.length === 0 &&
          moment(this.subscription.end).diff(moment(), "days")
        ) {
          this.subscription.end = null;
          this.subscription.start = null;
          this.subscription.package = null;
        }
        return;
      }
      if (this.subscription.end) {
        return;
      }
      if (moment().diff(moment(this.subscription.agreed_at), "days") > 30) {
        return;
      }

      let prevSubscription = Object.values(this.facility.subscriptions).slice(
        -2
      )[0];
      if (prevSubscription) {
        let daysFromPrevSubscriptionEnds = moment(
          this.subscription.agreed_at
        ).diff(moment(prevSubscription.end), "days");
        let newClient =
          prevSubscription.end === null || (
          prevSubscription.end !== null &&
            (moment(this.subscription.agreed_at).diff(
              moment(prevSubscription.end),
              "days"
            ) > 356));

        if (
          prevSubscription.client_status === 0 ||
          prevSubscription.client_status === 1
        ) {
          this.subscription.client_status = 0;
        }
        if (prevSubscription.client_status === null || newClient) {
          this.subscription.client_status = 1;
        }
        if (
          prevSubscription.end !== null &&
          !newClient &&
          daysFromPrevSubscriptionEnds < 31
        ) {
          this.subscription.start = moment(prevSubscription.end)
            .add(1, "days")
            .format("YYYY-MM-DD");
          let end = moment(prevSubscription.end).add(1, "year");
          this.subscription.end = end.format("YYYY-MM-DD");
        } else {
          let start = moment(this.subscription.agreed_at)
            .add(1, "M")
            .startOf("month");
          this.subscription.start = moment(this.subscription.agreed_at).format(
            "YYYY-MM-DD"
          );
          this.subscription.end = moment(start)
            .add(1, "year")
            .subtract(1, "days")
            .format("YYYY-MM-DD"); //add(1,'month').
        }
      }
    },
  },
};
</script>


<style>
#radioClientStatus .v-input--selection-controls__input {
  margin-right: 0 !important;
}
#radioClientStatus .v-radio {
  margin-right: 8px !important;
}
#facility-comment {
  color: black !important;
}
</style>