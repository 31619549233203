import Vue from "vue";
import Vuex from "vuex";

import User from "./modules/user";
import UserEvent from "./modules/userEvent";
import UserNotification from "./modules/userNotification";
import UserWorkScheduleException from "./modules/userWorkScheduleException";
import Task from "./modules/task";
import Facility from "./modules/facility";
import Listing from "./modules/listing";

import AddressType from "./modules/addressType";
import PhoneType from "./modules/phoneType";
import PropertyType from "./modules/propertyType";
import BankAccountCurrency from "./modules/bankAccountCurrency";

import Package from "./modules/package";
import Operator from "./modules/operator";
import Invoice from "./modules/invoice";
import MessageTemplate from "./modules/messageTemplate";
import messageTemplateType from "./modules/messageTemplateType";
import MessageTemplateCategory from "./modules/messageTemplateCategory";

import Qa from "./modules/qa";

import City from "./modules/city";
import Land from "./modules/land";
import Lake from "./modules/lake";
import Voivodeship from "./modules/voivodeship";
import Region from "./modules/region";
import Registration from "./modules/registration";

/*import FacilitySearch from "./modules/facilitySearch";*/

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: User,
    userEvent: UserEvent,
    userNotification: UserNotification,
    userWorkScheduleException: UserWorkScheduleException,
    task: Task,
    facility: Facility,
    listing: Listing,
    registration: Registration,
    addressType: AddressType,
    phoneType: PhoneType,
    bankAccountCurrency: BankAccountCurrency,
    propertyType: PropertyType,
    package: Package,
    land: Land,
    lake: Lake,
    city: City,
    voivodeship: Voivodeship,
    region: Region,
    operator: Operator,
    invoice: Invoice,
    messageTemplate: MessageTemplate,
    messageTemplateType: messageTemplateType,
    messageTemplateCategory: MessageTemplateCategory,
    qa: Qa
  }
});