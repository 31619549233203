var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-toolbar',{class:((_vm.viewMode === 'tab')?'mb-n12':''),staticStyle:{"z-index":"2"},attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"info","small":""},on:{"click":function($event){_vm.invoiceListDialog=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("receipt_long")])],1)]}}])},[_c('span',[_vm._v("zobacz faktury obiektu")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","small":""},on:{"click":function($event){_vm.newSubscriptionConfirmationDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("rozpocznij nową emisje")])]),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-btn-toggle',{attrs:{"mandatory":"","dense":""},model:{value:(_vm.viewMode),callback:function ($$v) {_vm.viewMode=$$v},expression:"viewMode"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"tab","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("tab")])],1)]}}])},[_c('span',[_vm._v("widok zakładek")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"list","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("list")])],1)]}}])},[_c('span',[_vm._v("widok całej listy emisji")])])],1)],1)],1)],1),(_vm.viewMode === 'list')?_vm._l((_vm.subscriptions),function(subscription){return _c('v-card',{key:subscription.id,staticClass:"mb-3"},[_c('v-toolbar',{attrs:{"color":"blue","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.makeTitle(subscription)))])],1),_c('SubscriptionEdit',{attrs:{"subscription":subscription,"archive":_vm.isArchive(subscription),"facility":_vm.facility}})],1)}):_vm._e(),(_vm.viewMode === 'tab')?[_c('FacilityEditSubscriptionTabs',{attrs:{"facility":_vm.facility}})]:_vm._e(),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.invoiceListDialog),callback:function ($$v) {_vm.invoiceListDialog=$$v},expression:"invoiceListDialog"}},[_c('FacilityEditInvoiceList',{attrs:{"facility":_vm.facility},on:{"close":function($event){_vm.invoiceListDialog=false}}})],1),_c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.newSubscriptionConfirmationDialog),callback:function ($$v) {_vm.newSubscriptionConfirmationDialog=$$v},expression:"newSubscriptionConfirmationDialog"}},[_c('v-card',{staticClass:"pb-5"},[_c('v-card-title',[_c('v-container',{staticClass:"text-center"},[_vm._v("Czy na pewno chcesz rozpocząć kolejną emisje?")])],1),_c('v-card-text'),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.newSubscriptionConfirmationDialog=false;_vm.addSubscription()}}},[_vm._v("tak, rozpoczynam nową emisje")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){_vm.newSubscriptionConfirmationDialog=false;}}},[_vm._v("nie, nie chce tworzyć nowej emisji")])],1)],1)],1),_c('Snackbar',{ref:"snackbar"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }