var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"calendar"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-btn',{attrs:{"disabled":_vm.todayIsStart || _vm.loading,"fab":"","small":""},on:{"click":_vm.prevPeriod}},[_c('v-icon',[_vm._v("arrow_back_ios_new")])],1)],1),_c('div',{staticClass:"col text-center"},[_c('v-btn',{attrs:{"disabled":_vm.todayIsStart || _vm.loading,"fab":"","small":""},on:{"click":_vm.todayPeriod}},[_c('v-icon',[_vm._v("restart_alt")])],1)],1),_c('div',{staticClass:"col text-right"},[_c('v-btn',{attrs:{"disabled":_vm.loading,"fab":"","small":""},on:{"click":_vm.nextPeriod}},[_c('v-icon',[_vm._v("arrow_forward_ios")])],1)],1)])])]),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"3","lg":"2"}},[_c('div',{staticClass:"text-center calendar-col-header"},[_vm._v("osoba")]),_c('div',{staticClass:"slots"},_vm._l((_vm.usersWorkScheduleExptions),function(usersWorkScheduleExption){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(usersWorkScheduleExption.user.active),expression:"usersWorkScheduleExption.user.active"}],key:usersWorkScheduleExption.user.id,staticClass:"mt-1"},[_c('v-alert',{staticClass:"ma-0 pa-0",attrs:{"color":"blue-grey lighten-4","dark":"","dense":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":"","color":_vm.activityColor(usersWorkScheduleExption.user)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":_vm.activityColor(usersWorkScheduleExption.user)}},'v-icon',attrs,false),on),[_vm._v("circle")])]}}],null,true)},[(usersWorkScheduleExption.user.last_activity_at)?_c('span',[_vm._v("online "+_vm._s(_vm.calculateFromNow(usersWorkScheduleExption.user.last_activity_at)))]):_vm._e()]),_c('span',{staticClass:"grey--text text--darken-3 ml-1",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(usersWorkScheduleExption.user.name))])],1)],1)}),0)]),_vm._l((_vm.days),function(day){return _c('div',{key:day.start,class:day.date === _vm.today.format("Y-MM-DD") ? "col calendar-col calendar-col-highlighted" : "col calendar-col"},[_c('div',{staticClass:"text-center calendar-col-header"},[_vm._v(_vm._s(day.label))]),_c('div',{staticClass:"slots"},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._l((_vm.usersWorkScheduleExptions),function(usersWorkScheduleExption){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(usersWorkScheduleExption.user.active),expression:"usersWorkScheduleExption.user.active"}],key:usersWorkScheduleExption.user.id,staticClass:"ma-1"},[(!day.weekend)?[(usersWorkScheduleExption.exceptions[day.date])?[(usersWorkScheduleExption.exceptions[day.date].type == 1)?_c('v-tooltip',{attrs:{"bottom":"","color":"orange darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-alert',_vm._g(_vm._b({staticClass:"text-center ma-0 pa-0",attrs:{"color":"orange darken-1","dark":"","dense":""}},'v-alert',attrs,false),on),[_c('v-icon',[_vm._v("do_not_disturb_on")])],1)]}}],null,true)},[_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v("urlop")]),_c('p',{staticClass:"mb-0 text-center"},[_vm._v(_vm._s(usersWorkScheduleExption.exceptions[day.date].description))])]):_vm._e(),(usersWorkScheduleExption.exceptions[day.date].type == 0)?_c('v-tooltip',{attrs:{"bottom":"","color":"light-blue darken-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-alert',_vm._g(_vm._b({staticClass:"text-center text ma-0 pa-0",attrs:{"color":"light-blue darken-1","dark":"","dense":""}},'v-alert',attrs,false),on),[_c('div',{staticClass:"text-truncate text-caption",staticStyle:{"max-width":"80px","margin":"3px auto"}},[_vm._v(_vm._s(usersWorkScheduleExption.exceptions[day.date].description))])])]}}],null,true)},[_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v("zmiana godzin pracy")]),_c('p',{staticClass:"mb-0 text-center"},[_vm._v(_vm._s(usersWorkScheduleExption.exceptions[day.date].description))])]):_vm._e()]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-alert',_vm._g(_vm._b({staticClass:"text-center ma-0 pa-0",staticStyle:{"background-color":"white!important"},attrs:{"color":"blue-grey lighten-2","dense":"","outlined":""}},'v-alert',attrs,false),on),[(usersWorkScheduleExption.user.work_schedule)?_c('div',{staticClass:"text-caption text-truncate",staticStyle:{"max-width":"80px","margin":"3px auto"}},[_vm._v(_vm._s(usersWorkScheduleExption.user.work_schedule.standardStart)+" - "+_vm._s(usersWorkScheduleExption.user.work_schedule.standardEnd))]):_vm._e(),(!usersWorkScheduleExption.user.work_schedule)?_c('div',{staticClass:"text-caption text-truncate",staticStyle:{"max-width":"80px","margin":"3px auto"}},[_vm._v("8:00 - 16:00")]):_vm._e()])]}}],null,true)},[_c('p',{staticClass:"font-weight-bold text-center"},[_vm._v("standardowe godziny pracy")]),(usersWorkScheduleExption.user.work_schedule)?_c('p',{staticClass:"mb-0 text-center"},[_vm._v(_vm._s(usersWorkScheduleExption.user.work_schedule.standardStart)+" - "+_vm._s(usersWorkScheduleExption.user.work_schedule.standardEnd))]):_vm._e(),(!usersWorkScheduleExption.user.work_schedule)?_c('p',{staticClass:"mb-0 text-center"},[_vm._v("8:00 - 16:00")]):_vm._e()])]]:[_c('v-alert',{staticClass:"text-center ma-0",attrs:{"color":"blue-grey lighten-4","dense":"","outlined":""}})]],2)})],2)])})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }